@font-face{
    src: url('/ubuntu-medium.ttf');
    font-family: fontMedium
}
@font-face{
    src: url('/ubuntu-regular.ttf');
    font-family: fontRegular
}
@font-face{
    src: url('/ubuntu-light.ttf');
    font-family: fontLight
}
@font-face{
    src: url('/ubuntu-bold.ttf');
    font-family: fontBold
}
// @font-face{
//     src: url('/fonts/open-sans-b.ttf');
//     font-family: font
// }
.darkmode .x-hide-darkmode{
    display: none
}
.lightmode .x-hide-darkmode{
    display: block
}
.darkmode .x-hide-lightmode{
    display: block
}
.lightmode .x-hide-lightmode{
    display: none
}