.y-profile {
  .y-main {
    margin-top: 10em;
    padding: 0rem 8em 4em;
    @include mobile() {
      padding: 4rem 2em 4em;
    }
    .y-form-holder {
      padding: 4rem 5rem;
      @include mobile() {
        padding: 4rem 0rem;
      }
      .y-feedback {
        width: 70%;
        margin: 0 auto;
        margin-top: 1rem;
      }
      .uploadfile {
        display: none;
      }
      .img-holder {
        background-position: center;
        background-size: cover;
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        background-repeat: no-repeat;
        margin: 0 auto 0.5rem;
        // margin-bottom: 0.4rem;
        // img {
        //   height: 5rem;
        //   border-radius: 50%;
        //   width: 5rem;
        // }
      }
      .y-action {
        color: $primary;
        cursor: pointer;
      }
      .y-form-main {
        margin: 0 auto;
        padding-top: 2rem;
        width: 70%;
        @include mobile() {
          width: 100%;
        }
        .y-input-holder {
          @include mobile() {
            margin-bottom: 1rem;
          }
        }
        input, .ui.selection{
          // background: $input-grey-color;
          box-shadow: unset;
          outline: unset;
          border: 1px solid $lightergrey;
          color: $black-font-color;
          // border: 1px solid $input-grey-border-color;
          margin-bottom: 0em;
          font-size: 0.9em;
          height: 3.5rem;
          &:focus{
          // background: $input-grey-color;
          }
        }
        .ui.selection.dropdown {
          // .text {
          //     padding-top: 0.5rem;
          // }
          i.dropdown.icon {
              padding-top: 1.5rem;
          }
        }
        .ui.selection.active.dropdown .menu {
          border-color: transparent;
        }
        .btn-holder {
          display: flex;
          padding-top: 3rem;
          button {
            margin: 0 auto;
            width: 18%;
            @include mobile() {
              width: 100%;
            }
          }
        }
      }
    }
  }
}