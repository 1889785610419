.x-searchbox{
    margin-bottom: 2em;
    .ui.dropdown>.text, span, i, .ui.selection.visible.dropdown>.text:not(.default){
        color: $grey-color;
    }
    .ui.selection.active.dropdown .menu{
        border: unset;
    }
    .inputWrapper{
        box-shadow: $box-shadow;
        i.dropdown.icon {
            margin-top: -0.5rem;
        }
        @include mobile() {
           display: block;
            button{
                width: 100%;
                margin-top: 1em !important;
                border-radius: 5px !important;
                justify-content: center;
            }
          }
    }
    input, .ui.selection {
        border: 0;
        border-radius: unset;
        font-size: 0.7em;
        @include mobile() {
            width: 100% ;   
            font-size: 0.6em;
        }
        &::placeholder{
            color:  $grey-color !important;
            @include mobile() {
               }
        }
    }
    // .ui.search.from-home {
    //     .ui.input {
    //         height: 5rem;
    //     }
    // }
    // .ui.selection.dropdown.from-home {
    //     .text {
    //         padding-top: 0.5rem;
    //     }
    //     i.dropdown.icon {
    //         padding-top: 2rem;
    //     }
    // }
    // button.from-home {
    //     font-size: 18px;
    // }
    .x-right{
        @include mobile() {
            text-align: left !important;
            margin-top: 2rem !important;
        }
        .ui.selection {
            background: $light-white;
           font-size: 15px;
         }
         i.dropdown.icon {
             margin-top: -0.2rem;
         }
         button{
            background: $light-white !important;
            i{
                color: $black-font-color;
            }
         }
    }
    .x-flex{
        width: 100%;
        display: flex;
        float: right;
        background: $light-white !important;
        justify-content: space-between;
        @include mobile() {
                  float: unset;
                 
        }
        button{
            background: $light-white !important;
            i{
                color: $black-font-color;
            }
         }
    }
    .ui.menu{
        width: 60%;
        min-width: 20%;
        margin: 0;
        border: none;
        box-shadow: none;
        background: $light-white !important;
     
    }
    .ui.menu>.item:first-child{
        width: 100%;
    }
    .ui.menu .ui.dropdown .menu>.item{
        background: #fff !important;
        padding: 0 ;
        .ui.dropdown .menu>.header{
        background: #fff !important;
    }
        &.x-search-item{
            padding: 0 !important
        }
        .ui.dropdown{
            width: 100%;
            padding: 1em 2em;
        }
       .dropdown.icon{
        margin: 0.7em 0 0;
        }
    }
    .ui.menu .pointing.dropdown.item .menu.transition{
        height: fit-content;
    }
}