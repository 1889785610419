    .x-courses{
        
        .x-main{
            margin-top: 10em;
            padding: 0 7em;    
            @include mobile() {
            margin-top: 7em;
            padding: 0 1em; 
            }            
            h1{
                font-size: 3em;
                @include mobile() {
                font-size: 1.5em
              }
              }
            .ui.pagination.menu{
                border: unset;
                box-shadow: unset;
                margin-bottom: 3em !important;
                .item:before{
                    position: unset;
                }
                a{
                    &.active{
                        background: #f8f8f8;
                    }
                    min-width: unset;
                    padding: 1em 0.9em;
                    height: 2em;
                    outline: unset;
                    box-shadow: unset;
                    &:first-of-type, &:last-of-type{
                        background: $lightergrey;
                    }
                }
            }
        }
        
    }