.y-searchbox{
    margin-top: -1rem;
    @include mobile() {
        margin-top: 1em;
        margin-bottom: 1rem;
       }
    .ui.dropdown>.text, span, i, .ui.selection.visible.dropdown>.text:not(.default){
        color: $grey-color;
    }
    .ui.selection.active.dropdown .menu{
        border: unset;
    }
    .inputWrapper{
        box-shadow: $box-shadow;
        @include mobile() {
           display: block;
           }
    }
    input, .ui.selection {
        border: 0;
        border-radius: unset;
        font-size: 0.7em;
        &::placeholder{
            color:  $grey-color !important
        }
        @include mobile() {
         width: 100%;
            }
    }
    button {
        font-size: 18px;
        @include mobile() {
           width: 100%;
           border-radius: 5px !important;
           margin-top: 1em !important;
           justify-content: center;
            }
    }
}