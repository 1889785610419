.y-enroll {
  padding: 4em 15em;
  background:  $very-light-primary;
  @include mobile(){
  padding: 2em
  }
  .y-top-heading {
    font-size: 45px;
   color: $black-font-color;
    text-shadow: 1px 0 #000;
    letter-spacing: 2px;
    font-weight: 900;
    margin-bottom: 2rem;
    text-align: center;
    @include mobile(){
      font-size: 1.5em;
    }
  }
  .y-footnote {
    font-size: 18px;
    text-align: center;
    margin-bottom: 2rem;
    width: 50%;
    margin: 0 auto;
    letter-spacing: 1px;
    margin-top: -0.6rem;
    @include mobile(){
      font-size: 1.1em;
      width: 100%;
    }
  }
  .btn-holder {
    display: flex;
    padding-top: 3rem;
    button {
      margin: 0 auto;
    }
  }
}