.x-coursecard{
    // box-shadow: $box-shadow;
    border: 1px solid rgba($color: $light-grey,  $alpha: 0.3);
    background: $white-color;
    font-family: fontRegular;
    margin-bottom: 2em;

    &:hover{
        margin-top: -0.5em;
        transition: 0.2s ease-in;
    }
    cursor: pointer;
    .bg{
        width: 100%;
        height: 15rem;
        background-position: center;
        background-size: contain;
    }
    .x-progress{
        padding: 1em 1.4em;
        text-align: right;
        .ui.progress {
            margin: 0;
            background:rgba($color:  $primary, $alpha: 0.2)
        }
        .ui.progress .bar{
            height: 0.4em;
            background: $primary

        }
        span{
            font-size: 0.9em;
            color: $primary;
            font-weight: bold;
        }
    }
    .x-content{
        padding: 0.5em 1.4em;
        .x-sub{
            padding: 1em 0 0.5em;
        }
        .x-title{
            font-size: 1.2em;
            font-family: fontBold;
            line-height: 25px;
        }
        .x-desc{
            padding: 0.5em 0;
        }
    }
    .delte-holder {
        // margin-top: -5rem;
        padding:  0 1rem 1rem;
        button {
            background: $primary;
            // width: 100%;
        }
    }
    .x-footnote{
        display: flex;
        justify-content: space-between;
        padding: 1em;
        border-top: 1px solid $lightergrey;
        .x-price{
            font-family: fontBold;
            display: flex;
            .discount {
                margin-right: 0.5rem;
            }
        }
        .active.icon{
            color: #ecaa43!important;
            background: #fff !important
        }
        .x-rating{
            font-size: 0.8em;
            color: $grey-color
        }
    }
}