.y-home {
  .y-main {
    margin-top: 8em;
    // padding: 4em 5em;
    &.noflash {
      margin-top: 7em;
      @include mobile() {
        margin-top: 5em;
      }
    }
    @include mobile(){
      margin-top: 8em;

    }
    .y-banner {
      padding: 6em 8em 4em;
      background-image: url("/images//home/home.png");
      background-repeat: no-repeat;
      // height: 100%;
      background-size: cover;
      background-position-x: 85%;
      @include mobile() {
        padding: 4em 2em;
        background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1)), url("https://i.pinimg.com/originals/96/59/78/965978a8bc090cded409804ee644557b.jpg") no-repeat;
      // background-size: contain;
      color: $white-color;
        // background-position: left;
      }
      .y-top-heading {
        font-size: 50px;
        color: $black-font-color;
        text-shadow: 1px 0 #000;
        letter-spacing: 2px;
        font-family: fontBold;
        margin-bottom: 2rem;
        @include mobile() {
      color: $white-color;
      font-size: 30px;
        }
      }
      .y-footnote {
        font-size: 1.3em;
        letter-spacing: 1px;
        margin-bottom: 2rem;
        line-height: 25px;
        @include mobile() {
          font-size: 1.1em;
          display: none;
        }
      }
      .y-watch {
        color: $primary;
        font-size: 15px;
        cursor: pointer;
      }
      // .img-holder {
      //   background-image: url("/images//home/home.png");
      //   background-repeat: no-repeat;
      //   height: 100%;
      //   background-size: cover;
      // }
    }
    .y-education {
      padding: 4em 8em;
      @include mobile() {
        padding: 4em 2em;
      }
      .y-topmost-heading {
        color: $primary;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: -1rem;
        @include mobile() {
          font-size: 1.1em;
        }
      }
      .y-top-heading {
        font-size: 45px;
        color: $black-font-color;
        text-shadow: 1px 0 #000;
        letter-spacing: 2px;
        font-weight: 900;
        margin-bottom: 2rem;
        text-align: center;
        @include mobile() {
          font-size: 1.5em;
        }
      }
      .y-footnote {
        font-size: 1.1em;
        text-align: center;
        margin-bottom: 2rem;
        width: 50%;
        margin: 0 auto;
        letter-spacing: 1px;
        margin-top: -0.6rem;
        @include mobile() {
          width: 100%;
        }
      }
      .y-features {
        padding-top: 4rem;
        .y-feature-card {
          box-shadow: $box-shadow;
          font-family: fontRegular;
          margin-bottom: 2em;
          padding: 1rem;
          border-radius: 5px;
          height: 20rem;
          &:hover{
            // margin-top: -0.5em;
            transition: 0.2s ease-in;
          }
          .img-holder {
            padding: 1rem 1rem 0.5rem;
            border-radius: 20px;
            background: rgba($color: $primary, $alpha: 0.2);
            display: inline-block;
            // width: 35%;
            // margin-bottom: 1rem;
            img {
              height: 3rem;
            }
          }
          .top-title {
            font-size: 1.3em;
            color: $black-font-color;
            text-shadow: 1px 0 #000;
            font-weight: 900;
            @include mobile() {
             br{
               display: none;
             }
            }
            // margin-top: 1rem;
          }
          .footnote {
            color: $grey-color;
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }
    .y-aboutus {
      position: relative;
      padding: 4em 8em;
      @include mobile() {
        padding: 0em 2em;
      }
      .y-float-btn {
        position: absolute;
        top: 63.5%;
        left: 45%;
        z-index: 1;
        @include mobile() {
          display: none;
        }
        i.graduation.cap.icon {
          margin-right: 0.9rem;
          font-size: 25px;
        }
        .btn-info {
          display: flex;
          align-items: center;
          line-height: unset;
          span {
            text-align: left;
            font-size: 11px;
            position: relative;
            &:after {
              display: block;
              content: '';
              width: 29%;
              height: 0.1rem;
              background: $white-color;
              position: absolute;
              bottom: -4px;
              left: 0;
            }
          }
        }
      }
      .y-topmost-heading {
        color: $primary;
        letter-spacing: 1px;
        @include mobile() {
          font-size: 1.1em;
          }
      }
      .y-top-heading {
        font-size: 35px;
        color: $black-font-color;
        text-shadow: 1px 0 #000;
        letter-spacing: 2px;
        font-family: fontBold;
        margin-bottom: 3rem;
        @include mobile() {
        font-size: 1.5em;
        }
      }
      .y-footnote {
        font-size: 15px;
        margin-bottom: 2rem;
        letter-spacing: 1px;
        margin-top: -0.6rem;
        width: 85%;
        @include mobile() {
          width: 100%;
        }
      }
      ul {
        list-style: none;
        padding-left: 0rem;
        margin-bottom: 3rem;
        width: 80%;
        @include mobile() {
          width: 100%;
        }
        li {
          margin-bottom: 2rem;
        }
        .list-info {
          font-size: 15px;
          @include mobile() {
            padding-left: 0.5rem;
          }
        }
        .icon-holder {
          border-radius: 10px;
          border: 1px solid $primary;
          padding: 0.2rem 0.2rem;
          height: 2.5rem;
          width: 2.5rem;
          .inner {
            background: $primary;
            height: 100%;
            border-radius: 10px;
            text-align: center;
            i.checkmark.icon {
              // padding: 0.6rem 1rem;
              color: $white-color;
            }
          }
        }
      }
      button {
        font-size: 18px;
        @include mobile(){
          width: 100%;
        }
      }
      .y-watch {
        color: $primary;
        font-size: 15px;
        cursor: pointer;
        margin-top: 1rem;
        @include mobile() {
          margin: 2rem 0;
          text-align: center;
        }
      }
      .img-holder {
        img {
          width: 100%;
          border-radius: 8px;
          height: 35rem;
          object-fit: cover;
          @include mobile() {
            object-fit: contain;
            height: unset;

          }
        }
      }
    }
    .y-achievements {
      padding: 4em 15em;
      display: none;
      @include mobile() {
        padding: 4em 2em;
      }
      background:  $very-light-primary;
      .y-top-heading {
        font-size: 45px;
       color: $black-font-color;
        text-shadow: 1px 0 #000;
        letter-spacing: 2px;
        font-weight: 900;
        margin-bottom: 2rem;
        text-align: center;
        @include mobile() {
        font-size: 1.5em;
      }
      }
      .y-footnote {
        font-size: 18px;
        text-align: center;
        margin-bottom: 2rem;
        width: 50%;
        margin: 0 auto;
        letter-spacing: 1px;
        margin-top: -0.6rem;
        @include mobile() {
          width: 92%;
          font-size: 1.1em
        }
      }
      .btn-holder {
        display: flex;
        padding-top: 3rem;
        button {
          margin: 0 auto;
        }

        @include mobile() {
          padding-top: 0
        }
      }
      .y-details {
        padding-top: 4rem;
        @include mobile() {
          text-align: center;
        }
        .top-title {
          font-size: 30px;
          color: $black-font-color;
          text-shadow: 1px 0 #000;
          font-weight: 900;
          margin-bottom: -0.1rem;
        }
        .footnote {
          // color: $grey-color;
          font-size: 16px;
          @include mobile() {
           margin-bottom: 2em;
          }
        }
      }
    }
    .y-courses {
      padding: 4em 8em;
      @include mobile() {
        padding: 4em 2em;
      }
      .y-topmost-heading {
        color: $primary;
        letter-spacing: 1px;
        margin-bottom: -1rem;
        @include mobile() {
         font-size: 1.1em;
        }
      }
      .y-top-heading {
        font-size: 45px;
       color: $black-font-color;
        text-shadow: 1px 0 #000;
        letter-spacing: 2px;
        font-weight: 900;
        margin-bottom: 2rem;
        @include mobile() {
          font-size: 1.5em;
         }
      }
      .y-footnote {
        font-size: 15px;
        margin-bottom: 2rem;
        margin: 0 auto;
        letter-spacing: 1px;
        margin-top: -0.6rem;
        @include mobile() {
          padding-left: 1rem;
        }
      }
      .btn-holder {
        display: flex;
        padding-top: 3rem;
        button {
          margin: 0 auto;
        }
      }
      @include mobile(){
        .column{
          padding: 0 !important
        }
      }
    }
    .y-instrustors {
      padding: 4rem 7em 4em 9rem;
      @include mobile() {
        padding: 4em 2em;
      }
      .y-controller {
        padding-right: 1rem;
        margin-bottom: 3rem;
        .controller-holder {
          display: flex;
          justify-content: flex-end;
          img {
            height: 2rem;
            cursor: pointer;
            &:first-child {
              margin-right: 1rem;
            }
          }
        }
      }
      .y-main-holder {
        .y-instr-card {
          width: 95% !important;
          margin-bottom: 2rem;
          cursor: pointer;
          &:hover {
            .y-name {
              &:after {
                opacity: 1;
              }
            }
          }
          .img-holder {
            margin-bottom: 2rem;
            img {
              border-radius: 7px;
              height: 23rem;
              width: 100%;
              object-fit: cover;
            }
          }
          .y-name {
            font-size: 16px;
           color: $black-font-color;
           font-family: fontRegular;
            margin-bottom: 1rem;
            text-transform: capitalize;
            position: relative;
            &:after {
              -webkit-transition: opacity .5s ease-in-out;
              -moz-transition: opacity .5s ease-in-out;
              -o-transition: opacity .5s ease-in-out;
              transition: opacity .5s ease-in-out;
              display: block;
              content: "";
              width: 50%;
              height: 0.3rem;
              background: $primary;
              position: absolute;
              bottom: 0;
              left: 0;
              top: 1.7rem;
              opacity: 0;
            }
          }
          .y-rate {
            font-size: 16px;
            color: $orange-color;
            font-weight: 900;
            margin-bottom: 1rem;
          }
          .y-desc {
            font-size: 14px;
            margin-top: -0.6rem;
          }
        }
      }
    }
    .y-love-us {
      padding: 4em 1em;
      @include mobile() {
        padding: 4em 1em 1em;
      }
      background:  $very-light-primary;
      .y-top-heading {
        font-size: 45px;
        color: $black-font-color;
        text-shadow: 1px 0 #000;
        letter-spacing: 2px;
        font-weight: 900;
        margin-bottom: 2rem;
        text-align: center;
        @include mobile() {
          font-size: 1.5em;
        }
      }
      .y-footnote {
        font-size: 18px;
        text-align: center;
        margin-bottom: 2rem;
        width: 50%;
        margin: 0 auto;
        letter-spacing: 1px;
        margin-top: -0.6rem;
        @include mobile() {
          font-size: 1.1em;
          width: 100%;
        }
      }
      .y-testimonies {
        padding-top: 4rem;
        .testimony {
          background: $white-color;
          box-shadow: $box-shadow;
          font-family: fontRegular;
          margin-bottom: 2em;
          padding: 2rem;
          border-radius: 5px;
          .img-holder {
            margin-bottom: 1rem;
            img {
              border-radius: 50%;
              height: 3.5rem;
              width: 3.5rem;
              object-fit: contain;
              background: #000;
            }
          }
          .testi-note {
            font-size: 17px;
            @include mobile() {
              font-size: 1.1em;
            }
          }
          .tesit-name {
            margin-bottom: 0px;
            font-size: 16px;
           color: $black-font-color;
            font-weight: 900;
            text-shadow: 1px 0 #000;
          }
        }
      }
    }
    .y-watchall {
      padding: 4em 8em;
      @include mobile() {
        padding: 4em 2em;
      }
      .y-top-heading {
        font-size: 45px;
       color: $black-font-color;
        text-shadow: 1px 0 #000;
        letter-spacing: 2px;
        font-family: fontBold;
        margin-bottom: 2rem;
        @include mobile() {
          font-size: 1.5em;
        }
      }
      .y-footnote {
        font-size: 18px;
        margin-bottom: 2rem;
        letter-spacing: 1px;
        margin-top: -0.6rem;
        @include mobile() {
          font-size: 1.1em;
        }
      }
      .y-download {
        display: flex;
        img {
          height: 2.5rem;
          cursor: pointer;
          &:first-child {
            margin-right: 2rem;
          }
        }
      }
      .img-holder {
        img {
          height: 40rem;
        }
      }
      @include mobile(){
        .svgimg{
          width: 50% !important;
          margin: auto;
          margin-top: 2em;
      }
      }
    }
    // .y-enroll {
    //   padding: 4em 15em;
    //   background:  $very-light-primary;
    //   @include mobile() {
    //     padding: 4em 2em;
    //   }
    //   .y-top-heading {
    //     font-size: 45px;
    //    color: $black-font-color;
    //     text-shadow: 1px 0 #000;
    //     letter-spacing: 2px;
    //     font-weight: 900;
    //     margin-bottom: 2rem;
    //     text-align: center;
    //     @include mobile(){
    //       font-size: 1.5em;
    //     }
    //   }
    //   .y-footnote {
    //     font-size: 18px;
    //     text-align: center;
    //     margin-bottom: 2rem;
    //     width: 50%;
    //     margin: 0 auto;
    //     letter-spacing: 1px;
    //     margin-top: -0.6rem;
    //     @include mobile(){
    //       font-size: 1.1em;
    //       width: 100%;
    //     }
    //   }
    //   .btn-holder {
    //     display: flex;
    //     padding-top: 3rem;
    //     button {
    //       margin: 0 auto;
    //     }
    //   }
    // }
  }
}