.y-upload {
  .y-main {
    margin-top: 10em;
    padding: 0rem 8em 4em;
    .upload-holder {
      padding: 4rem 5rem;
      .y-step-holder {
        // margin: 0 auto;
        // width: 70%;
        text-align: center;
      }
      .y-feedback {
        margin: 0 auto;
        width: 70%;
        margin-top: 3rem;
        p {
          margin: 0;
        }
      }
      .y-form-main {
        margin: 0 auto;
        width: 70%;
        padding-top: 2rem;
        &.course-sections {
          .addsection {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;
          }
          .sections-main {
            border: 1px solid $Light-border;
            padding: 1rem;
            border-radius: 5px;
            .section-holder {
              border: 1px solid $Light-border;
              padding: 1rem;
              border-radius: 5px;
              margin-bottom: 1rem;
              .input-withr-remove {
                display: flex;
                justify-content: space-between;
                i.times.icon {
                  cursor: pointer;
                }
              }
              .lecture-holder, .y-section, .y-lecture {
                border: 1px solid $Light-border;
                padding: 1rem;
                border-radius: 5px;
                margin-bottom: 1rem;
              }
            }
          }
        }
        .btn-holder {
          display: flex;
          padding-top: 3rem;
          button {
            margin: 0 auto;
          }
        }
        .y-input-holder {
         padding: 1rem 0;
         &.image,&.video {
           position: relative;
           .ui.active.loader {
             position: absolute;
             top: 4.5rem;
              left: 2rem;
           }
           button {
             margin-top: 2rem;
           }
         }
         .custom-label {
           display: flex;
           justify-content: space-between;
           align-items: flex-end;
           margin-bottom: 1rem;
           p {
             margin-bottom: 0;
           }
         }
         .faq-holder {
           margin-bottom: 1rem;
           .err-msg {
             color: $red;
           }
          //  .cancel {
          //    background: $red !important;
          //  }
          //  .add {
          //   background: $blue !important;
          //  }
         }
         .course-image {
           display: flex;
          .img-holder {
            background-position: center;
            background-size: cover;
            height: 7rem;
            width: 7rem;
            border-radius: 5px;
            background-repeat: no-repeat;
            margin-bottom: 1rem;
          }
          i.times.icon {
            cursor: pointer;
          }
         }
         .course-vid {
          display: flex;
          video {
            height: 7rem;
            width: 7rem;
            border-radius: 5px;
            margin-bottom: 1rem;
            outline: none;
          }
            i.times.icon {
              cursor: pointer;
            }
          }
         .course-uploadfile {
          display: none;
          }
        }
        input, .ui.selection, textarea{
          // background: $input-grey-color;
          box-shadow: unset;
          outline: unset;
          border: 1px solid $lightergrey;
          color: $black-font-color;
          // border: 1px solid $input-grey-border-color;
          margin-bottom: 0em;
          font-size: 0.9em;
          height: 3.5rem;
          &:focus{
          // background: $input-grey-color;
          }
        }
      }
    }
  }
}