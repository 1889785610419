.x-reviews-tab{
        .x-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1em 2em !important;
            font-size: 0.9em !important;
            text-transform: capitalize !important;
            background: rgba($color: $primary, $alpha: 0.04);
            margin-top: 1em;
            i{
                color: $primary !important;
            }
        }
        .x-content{
            padding: 2em !important;
                .x-desc{
                    margin-left: 1em;
                    i{
                        color: $primary ;
                        
                    }
                    div{
                        font-size: 0.9em;
                    
                    }
                }
            @include mobile() {
                padding: 2em 1em !important;
            }
        }
    .x-progress{
        padding: 1em 1.4em;
        text-align: right;
        .ui.progress {
            margin: 0;
            background:rgba($color:  $orange-color, $alpha: 0.2)
        }
        .ui.progress .bar{
            height: 0.4em;
            background:$orange-color;
            // background: $primary

        }
        span{
            font-size: 0.9em;
            color: $primary;
            font-weight: bold;
        }
    }
    .ui.grid.x-rows{
        .row{
            padding-top: 0;
            padding-bottom: 0 !important;
            .ui.medium.rating{
                width: 100%
            }
        }        
    }
    .ui.form{
        width: 100%;
    }
    .x-small{
        @include mobile(){
            font-size: 0.7em;
        }
    }
}