
body{
  &.darkmode{
      $primary: #a5606a;
      $white-color: #3f3d55;
      $black-font-color: #FFFFFF;
      $body-grey-bg:#1B2431;
      $box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 1);
      $orange-color: #ecaa43;
      $lightergrey: rgb(238, 238, 238);
      $grey-color: #888;
      $light-white: #f6f6f6;
      $light-grey:#BBBBBB;
      $lavenderblush: lavenderblush;
      $purple: #5e35b1;
      $light-purple: #ede7f6;
      $very-light-primary: #faf9f9;
      $light-green: #b9f6ca;
      $green: #00c853;
      $yellow: #ffd54f;
      $light-yellow: #3a371b;
      $Light-border: rgba(0, 65, 93, 0.1);
      $red: #dc3545;
      $blue: #007bff;

      @import './default.scss';    
      //page
      @import '../containers/signin/index.scss';
      // @import '../containers/dashboard/index.scss';
      // @import '../containers/settings/index.scss';
      @import '../containers/courses/index.scss';
      @import '../containers/course/index.scss';
      @import '../containers/homepage/index.scss';
      @import '../containers/profile/index.scss';
      @import '../containers/profile/settings.scss';
      @import '../containers/messages/index.scss';
      @import '../containers/dashboard/index.scss';
      @import '../containers/checkout/index.scss';
      @import '../containers/upload/index.scss';
      @import '../containers/createcourse/index.scss';
      @import '../containers/profile/instructor.scss';
      @import '../containers/instructors/index.scss';
      @import '../containers/terms/index.scss';
      @import '../containers/contactus/index.scss';

      //component
      @import '../components/navbar/index.scss';
      @import '../components/courses/searchbox.scss';
      @import '../components/courses/coursecard.scss';
      @import '../components/courses/searchbox.scss';
      @import '../components/course/infotab.scss';
      @import '../components/course/reviews.scss';
      @import '../components/course/paidInfotab.scss';
      @import '../components/course/accordion.scss';
      @import '../components/course/paidAccordion.scss';
      @import '../components/footer/footer.scss';
      @import '../components/homepage/searchbox.scss';
      @import '../components/footer/enroll.scss';
      @import '../components/upload/videomodal.scss';
      @import '../components/messages/filemodal.scss';

  }
  &.lightmode{
      $primary: #a5606a;
      $white-color: #fff;
      $black-font-color: #202224;
      $body-grey-bg:#fff;
      $box-shadow:  0px 3px 12px rgba(0, 0, 0, 0.09);
      $orange-color: #e68211;
      $lightergrey: rgb(238, 238, 238);
      $grey-color: #888;
      $light-white: #f6f6f6;
      $light-grey:#BBBBBB;
      $lavenderblush: lavenderblush;
      $purple: #5e35b1;
      $purple: #7281d8;
      $light-purple: #ede7f6;
      $very-light-primary: #faf9f9;
      $light-green: #e8f5e9;
      $green: #00c853;
      $yellow: #ffd54f;
      $light-yellow: #fffde7;
      $Light-border: rgba(0, 65, 93, 0.1);
      $red: #dc3545;
      $blue: #007bff;

      @import './default.scss'; 

      //page
      @import '../containers/signin/index.scss';
      @import '../containers/dashboard/index.scss';
      // @import '../containers/settings/index.scss';
      @import '../containers/courses/index.scss';
      @import '../containers/course/index.scss';
      @import '../containers/mycourses/index.scss';
      @import '../containers/homepage/index.scss';
      @import '../containers/profile/index.scss';
      @import '../containers/profile/settings.scss';
      @import '../containers/messages/index.scss';
      @import '../containers/dashboard/index.scss';
      @import '../containers/checkout/index.scss';
      @import '../containers/upload/index.scss';
      @import '../containers/createcourse/index.scss';
      @import '../containers/profile/instructor.scss';
      @import '../containers/instructors/index.scss';
      @import '../containers/terms/index.scss';
      @import '../containers/contactus/index.scss';
      
      //component
      @import '../components/navbar/index.scss';
      @import '../components/courses/searchbox.scss';
      @import '../components/courses/coursecard.scss';
      @import '../components/courses/searchbox.scss';
      @import '../components/course/infotab.scss';
      @import '../components/course/paidInfotab.scss';
      @import '../components/course/reviews.scss';
      @import '../components/course/accordion.scss';
      @import '../components/course/paidAccordion.scss';
      @import '../components/footer/footer.scss';
      @import '../components/homepage/searchbox.scss';
      @import '../components/footer/enroll.scss';
      @import '../components/upload/videomodal.scss';
      @import '../components/messages/filemodal.scss';
  }
}