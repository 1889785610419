.addChatHead {
  height: 20rem;
  button {
    margin-top: 1rem;
  }
}
.ui.icon.input>i.icon:not(.link) {
  cursor: pointer !important;
  pointer-events: visible !important;
}
img.ui.avatar.image {
  width: 2rem !important;
  height: 2rem !important;
}
.y-messages {
  .y-main {
    margin-top: 12em;
    padding: 0rem 8em 4em;
    @include mobile() {
      padding: 0rem 2em 4em;
    }
    .topmain-holder {
      display: flex;
      justify-content: space-between;
    }
    .y-top-title {
      @include mobile() {
        font-size: 1.5em;
      }
    }
    .messages-box {
      padding-top: 2rem;
      .y-message-list{
        // border: 1px solid $lightergrey;
        box-shadow: $box-shadow;
        border-radius: 5px;
        @include mobile() {
          margin-bottom: 2rem;
        }
        .ml-top {
          padding: 1rem 4rem 1rem 2rem;
          @include mobile() {
            padding: 1rem 1rem 1rem 1rem;
          }
          .ml-top-head {
            @include mobile() {
              margin-bottom: 1rem;
            }
          }
          .search-input {
            @include mobile() {
              width: 100%;
            }
          }
          .ui.label {
            background-color: $primary;
            color: $white-color;
          }
          input, .ui.selection{
            // background: $input-grey-color;
            box-shadow: unset;
            outline: unset;
            border: 0.5px solid $lightergrey;
            color: $black-font-color;
            // border: 1px solid $input-grey-border-color;
            // margin-bottom: 0em;
            font-size: 1em;
            &:focus{
            
            }
          }
        }
        .ml-body {
          padding: 2rem 0;
          min-height: 29rem;
          max-height: 29rem;
          overflow-y: scroll;
          .nochathead {
            padding: 0 2rem;
          }
          .y-message-card {
            padding: 0 2rem;
            margin-bottom: 1rem;
            cursor: pointer;
            @include mobile() {
              padding: 0 1rem;
            }
            &.active, &:hover {
              background: $primary;
              color: $white-color;
              .no-time {
                .ui.label {
                  background: $white-color;
                  color: $primary;
                }
              }
            }
            .img-holder {
              @include mobile() {
                margin-right: 1rem;
              }
              img {
                height: 3rem;
                border-radius: 50%;
                width: 3rem;
              }
            }
            .name-msg {
              .namewithactive {
                display: flex;
                align-items: center;
              }
              .online {
                border-radius: 50%;
                background: $green;
                height: 0.5rem;
                width: 0.5rem;
                margin: 0 1rem;
              }
              span {
                margin-bottom: 0px;
                font-size: 16px !important;
              }
              p {
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .no-time {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              @include mobile() {
                margin-top: 1rem;
                flex-direction: row;
              }
              .ui.label {
                background-color: $primary;
                color: $white-color;
                @include mobile() {
                  margin-right: 2rem;
                }
              }
            }
          }
        }
        .ml-bottom {
          padding: 1rem;
          // display: none;
          button {
            height: 3.6rem;
          }
        }
      }
      .y-message-desk {
        box-shadow: $box-shadow;
        border-radius: 5px;
        input, .ui.selection{
          // background: $input-grey-color;
          box-shadow: unset;
          outline: unset;
          border: 0.5px solid $lightergrey;
          color: $black-font-color;
          // border: 1px solid $input-grey-border-color;
          // margin-bottom: 0em;
          font-size: 1em;
          &:focus{
          // background: $input-grey-color;
          }
        }
        .desk-top {
          background: $very-light-primary;
          padding: 1rem 2rem;
          @include mobile() {
            padding: 1rem 1rem;
          }
          .deskname {
            display: flex;
            align-items: center;
            @include mobile() {
              margin-bottom: 1rem;
            }
            .img-holder {
              margin-right: 1rem;
              img {
                height: 3rem;
                border-radius: 50%;
                width: 3rem;
              }
            }
            .namewithactive {
              display: flex;
              align-items: center;
            }
            .online {
              border-radius: 50%;
              background: $green;
              height: 0.5rem;
              width: 0.5rem;
              margin: 0 1rem;
            }
            h4 {
              margin-bottom: 0px;
              font-size: 18px !important;
            }
            p {
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .deskaction {
            display: flex;
            justify-content: flex-end;
            // align-items: center;
            @include mobile() {
              justify-content: flex-start;
              // justify-content: space-between;
              align-items: center;
            }
            // button {
            //   margin-right: 1rem;
            //   @include mobile() {
            //     margin-right: 0.5rem;
            //   }
            // }
            .search-holder {
              margin-right: 1rem;
              @include mobile() {
                margin-right: 0.5rem;
                width: 100%;
                // &.full {
                //   width: 100%;
                // }
                // &.half {
                //   width: 60%;
                // }
                .ui.icon.input {
                  width: 100%;
                }
              }
            }
            i.star {
              cursor: pointer;
              font-size: 18px;
              color: $light-grey;
              &.active {
                color: $primary;
              }
              @include mobile() {
                margin-top: -0.5rem;
              }
            }
          }
        }
        .desk-main {
          min-height: 27rem;
          max-height: 27rem;
          padding: 1rem 2rem;
          overflow-y: scroll; 
          display: flex;
          flex-direction: column;
          .send {
            position: relative;
            margin-left: 20px;
            margin-bottom: 10px;
            padding: 10px;
            background: $very-light-primary;
            text-align: left;
            border: 1px solid  $very-light-primary;
            border-radius: 10px;
            margin-bottom: 1rem;
            min-width: 50%;
            max-width: 80%;
            a {
              display: block;
              color: $primary;
            }
            .filetag {
              width: 20%;
              margin: 0.5rem 0 0.5rem 1rem;
              @include mobile() {
                width: 50%;
              }
            }
            &:after {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-top: 15px solid $very-light-primary;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              top: 0;
              left: -15px;
            }

            &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-top: 17px solid $very-light-primary;
              border-left: 16px solid transparent;
              border-right: 16px solid transparent;
              top: -1px;
              left: -17px;
            }
          }
          .recieve {
            align-self: flex-end;
            position: relative;
            margin-bottom: 10px;
            color: $white-color;
            padding: 10px;
            background: $primary;
            text-align: left;
            border: 1px solid $primary;
            border-radius: 10px;
            margin-bottom: 1rem;
            min-width: 50%;
            max-width: 80%;
            a {
              display: block;
              color: $white-color;
            }
            .filetag {
              width: 20%;
              margin: 0.5rem 0 0.5rem 1rem;
              background: $white-color;
              color: $primary;
              @include mobile() {
                width: 50%;
              }
              &:after {
                background-color: $primary !important;
              }
            }
            // margin-left: 50% max(calc(100% - 80%));
            &:after {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-bottom: 15px solid $primary;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              bottom: 0;
              right: -15px;
            }
          &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-bottom: 17px solid $primary;
              border-left: 16px solid transparent;
              border-right: 16px solid transparent;
              bottom: -1px;
              right: -17px;
            }
          }
          .message-timestamp-left {
            // position: absolute;
            font-size: .85em;
            font-weight: 300;
            // bottom: 5px;
            // left: 5px;
          }
          .message-timestamp-right {
            // position: absolute;
            font-size: .85em;
            font-weight: 300;
            // bottom: 5px;
            // right: 5px;
            text-align: right;
          }
        }
        .desk-footer {
          background: $very-light-primary;
          padding: 2rem;
          @include mobile() {
            padding: 1rem;
          }
          i {
            cursor: pointer;
            color: $light-grey;
          }
          .ui.button {
            @include mobile() {
              margin-top: 1rem;
              width: 100%;
            }
          }
        }
      }
    }
  }
}