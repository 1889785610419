
  &{
  font-family: fontRegular;
  background: $body-grey-bg;
  color: $black-font-color;
  // background: $white-color;

  }

  * {
  box-sizing: border-box;

  }


  input {

      outline: none !important;
      outline-offset: none !important;
      &:focus,&:hover,&:active {
        outline: none !important;
        outline-offset: none !important;
      }
  }
  .ui.progress .bar{
    min-width: 0;
  }
  h1,h2,h3,h4,h5{
  font-family: fontLight
  }
  p,div{
  line-height: 30px;

  @include mobile(){
      line-height: 25px;
      .no-xspad{
        padding: 0 !important;
    }
  }

  }
  a{
  color: $black-font-color;
  }
  button{
  background: $primary !important;
  color: $white-color !important
  }
  .ui.dimmer{
      .ui.button {
          background: transparent !important;
          &.primary-btn {
            background: $primary !important;
          }
  }
  }
  .no-padding{
  padding: 0 !important;
  }

  .x-spinner{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 1024;
  }
  .x-cursor{
    cursor: pointer;
  }
  .x-error{
    background: rgba($color: red, $alpha: 0.5);
    margin-bottom: 2em;
    padding: 1em;
    color: #fff;
    text-align: center;
}
  .no-margin{
  margin: 0 !important;
  }

  .ui.star.rating .active.icon{
  color: $orange-color !important;
  }
  .x-hidden-mobile{
  @include mobile(){
      display: none !important
  }
  }
  .x-hidden-tablet{
  @include tablet(){
      display: none !important
  }
  }
  .x-hidden-desktop{
    @include desktop(){
        display: none !important
    }
    }
  .slick-slider.slick-initialized {
  .slick-list {
      .slick-slide.slick-active {
          div {
              div {
                  outline: none !important;
              }
          }
      }
  }
}
.homepage-modal {
  video {
    width: 100%;
    height: 24rem;
    outline: none;
    // display: flex;
    @include mobile () {
      width: 100%;
      height: 24rem;
      outline: none;
    }
  }
}
.notification-info {
  background: $primary !important;
}