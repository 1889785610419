.x-infotab{
    margin: 2em 0;


    .ui.secondary.pointing.menu .active.item{
        color: $primary;
        border-bottom: 2px solid $primary
    }
    .ui.secondary.pointing.menu{
        border-bottom: unset;
    }
    .ui.segment{
        border: unset;
        box-shadow: unset;
        padding: 0
    }
    .x-alert{
        display: flex;
        justify-content: space-between;
        background: rgba($color: $primary, $alpha: 0.2);
        padding: 1em 2em;
        color: $primary;
        p{
            font-size: 0.9em;
        }
        @include mobile() {
            flex-direction: column;
        }
    }
    // .x-avatar{
    //     display: flex;
    //     padding: 1em 0em;
    //     margin: 2em 0;
    //     font-size: 0.8em;
    //     img{
    //         margin-right: 1em;
    //     }
    //     i{
    //         font-size: 0.6em;
    //     }
    // }
    .coursefiles-holder {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        .mainfile-holder {
            background: $very-light-primary;
            padding: 1rem;
        }
        .item {
            &:before {
              top: 0.5rem
            }
        }
        .list-content {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            @include mobile() {
                flex-direction: column;
            }
        }
      }
    .x-about{
        font-size: 0.9em;
    }
    .x-course-targets {
        // margin-bottom: 2rem;
        margin-top: 1.5rem;
        .item {
            line-height: 21px;
        }
    }
    .x-mentor{

        .x-avatar{
            display: flex;
            padding: 1em 0em;
            margin: 2em 0;
            @include mobile() {
                flex-direction: column;
            }
            img{
                margin-right: 2em;
                width: 60px !important;
                height: 60px !important;
                @include mobile() {
                    margin-bottom: 1rem
                }
            }
            h3{
                text-transform: capitalize;
                font-size: 1.3em;
                margin-bottom: 0.4em;
                color: $primary;
                cursor: pointer;
            }
            span{
                color: $grey-color;
            }
            button{
                margin-top: 1em;
                margin-right: 1em;
                background: transparent !important;
                color: $primary !important;
                border: 1px solid $primary !important
            }
        }
    }
    .x-course-faq{
        .x-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1em 2em !important;
            font-size: 0.9em !important;
            text-transform: capitalize !important;
            background: rgba($color: $primary, $alpha: 0.04);
            margin-top: 1em;
            i{
                color: $primary !important;
            }
        }
        .x-content{
            padding: 2em !important;
                .x-desc{
                    margin-left: 1em;
                    i{
                        color: $primary ;
                        
                    }
                    div{
                        font-size: 0.9em;
                    
                    }
                }
        }
    }
}