.y-instructor {
  .y-main {
    margin-top: 10em;
    padding: 3em 8em 4em;
    @include mobile() {
      padding: 3em 2em;
    }
    .details {
      .top-most-heading {
        color: $primary;
        letter-spacing: 1px;
      }
      .y-name {
        font-size: 35px;
        color: $black-font-color;
         text-shadow: 1px 0 #000;
         letter-spacing: 2px;
         font-weight: 900;
         margin: 0;
         text-transform: capitalize;
         margin-bottom: 1em;
      }
      .y-aboutme {
        margin: 5rem 0 0 0;
      }
      .students {
        display: flex;
        .tally {
          &:first-child {
            margin-right: 2rem;
          }
          h1 {
            margin: 0;
            text-shadow: 1px 0 #000;
            letter-spacing: 1px;
            font-weight: 500;
          }
        }
      }
     
    }
    .y-mycourses {
      .top-most-heading {
        color: $primary;
        letter-spacing: 1px;
      }
      margin: 5rem 0 0 0;
      .ui.pagination.menu{
        border: unset;
        box-shadow: unset;
        margin-bottom: 3em !important;
        .item:before{
            position: unset;
        }
        a{
            &.active{
                // background: unset;
                background: #f8f8f8;
              }
            min-width: unset;
            padding: 1em 0.9em;
            height: 2em;
            outline: unset;
            box-shadow: unset;
            &:first-of-type, &:last-of-type{
                background: $lightergrey;
            }
        }
    }
    }
    .img-holder {
      background-position: center;
      background-size: cover;
      height: 15rem;
      width: 15rem;
      border-radius: 50%;
      background-repeat: no-repeat;
      margin: 0 auto;
      // margin-bottom: 0.4rem;
      // img {
      //   height: 5rem;
      //   border-radius: 50%;
      //   width: 5rem;
      // }
      &.showononlylarge {
        @include mobile() {
          display: none;
        }
      }
      &.showononlysmall {
        display: none;
        @include mobile() {
          display: block;
          margin-bottom: 2rem;
        }
      }
    }
    .social-links {
      text-align: center;
      button{
        margin-top: 1em;
        margin-right: 1em;
        background: transparent !important;
        color: $primary !important;
        border: 1px solid $primary !important
     }
    }
    .seeall {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
    }
  }
}