
.x-navbar{
  position: relative;
    .flash{
        // padding: 2em;
        text-align: center;
        position: fixed;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 4em;
        left: 0;
        right: 0;
        top:0;
        background: $primary;
        color: $white-color;
        z-index: 1000;
        font-size: 0.9em;
        @include mobile(){
          line-height: 17px;
          padding: 1em
        }   
    }
    .ui.dropdown>.left.menu {
      @include mobile() {
      left: unset !important;
      right: unset !important;
      }
    }
    .nav-mobile {
      display: inline-block;
      cursor: pointer;
      // margin-right: 2rem;
      // margin-top: 0.8rem;
      @include desktop() {
        display: none;
      }
      @include mini-desktop () {
        display: block;
      }
      .bar1, .bar2, .bar3 {
        width: 30px;
        height: 2px;
        background-color: #000;
        margin: 6px 0;
        transition: 0.4s;
      }
      @include mobile() {
        .bar1, .bar2, .bar3 {
          width: 30px;
          height: 2px;
        }
      }
      @include mini-desktop() {
        .bar1, .bar2, .bar3 {
          width: 30px;
          height: 3px;
        }
      }
    }
    .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .change .bar2 {opacity: 0;}
    .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-5px, -8px);
    }
    .nav{
        position: fixed;
        left: 0;
        right: 0;
        top: 3em;
        background: $white-color;
        box-shadow: 0 0 1px rgba(0,65,94,0.9);
        z-index: 101;
        transition: height 600ms 0s ease;
        background: $white-color;
        border-bottom: 1px solid $lightergrey;
        list-style-type: none;
        margin: 0 !important;
        display: flex;
        padding: 2.5em 7em 2em;
        align-items: center;
        height: 70px;
        z-index: 1000;
        &.noflash {
          margin-top: -3.5rem !important;
          padding: 3.5em 7em 3em;
          @include mobile() {
            padding: 2.5em 1em 2em !important;
          }
        }
        @include mobile(){
          padding: 1em;
          top: 3.4em;
          li{
            display: none;
            &:first-child{
              display: block;
            }
          }
        }
        li {
          &:first-child{
            margin-right: auto;
            padding: 0;
            a{
              img{
                width: 10em;
              }
              @include mobile(){
                padding: 0;
                img{
                  width: 8em;
                }
              }
            }
          }
          &:last-child{
            a{
              // color: #1890ff;
            // margin-right: 2em;
            }
          }
          a {
            font-size: 1.1em;
            // color: #7b8b8e;
            font-weight: 500;
            position: relative;
            display: block;
            padding: 10px 15px;
            i{
              color: $primary;
            }
            &:hover{
              background: none;
            }
          }
          .x-profile-dropdown{
            display: flex;
            align-items: center;
          }
          .x-avatar{
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
              margin-right: 1em;
            }
          }
          button {
            // color: #7b8b8e;
            font-weight: 500;
            position: relative;
            display: block;
            padding: 10px 15px;
            &:hover{
              background: none;
            }
          }
        }
      }

  .sidebar {
    padding: 2rem 2rem;
    position: fixed;
    width: 260px;
    right: 0;
    left: 0;
    top: 8rem;
    margin: 0;
    height: 100%;
    height: calc(100% + 60px);
    height: -moz-calc(100%);
    padding-bottom: 60px;
    background-color: $white-color;
    z-index: 10000;
    overflow-y: auto;
    &.noflash {
      top: 5rem;
    }
    @include desktop() {
      display: none;
    }
    @include mini-desktop() {
      display: block;
    }
    ul {
      list-style: none;
      padding-left: 0;
      // display: flex;
      // align-items: center;
      // flex-direction: column;
      li {
        font-weight: 600;
        margin-bottom: 2rem;
        a {
          color: #000;
        }
      }
    }
  }
}