.y-contact {
  margin-top: 10em;
  padding: 3em 8em 4em;
  @include mobile() {
    padding: 3em 2em;
  }
  .y-main {
    margin-bottom: 4rem;
    .top-heading {
      font-size: 3em;
      text-align: center;
      text-shadow: 1px 0 #000;
      margin-bottom: 2rem;
      @include mobile() {
        font-size: 1.5em;
      }
    }
    .below-heading {
      text-align: center;
    }
    h2 {
      text-shadow: 1px 0 #000;
    }
    p, li {
      font-size: 15px;
    }
    .y-feedback {
      width: 55%;
      margin: 0 auto;
      @include mobile() {
        width: 100%;
      }
    }
    .contact-form {
      padding: 0 20rem;
      @include mobile() {
        padding: 0;
      }
      .y-input-holder {
        padding: 0.5rem 0;
      }
      .btn-holder {
        margin-top: 1rem;
      }
    }
  }
}