footer.x-footer{
    border-top: 1px solid $lightergrey;
    padding: 3em 8em 0;
    // margin-top: 3em;
    background: $white-color;
    @include mobile() {
      padding: 3em 1em 0;
    }
    img{
        width: 10em;
    }
    .column{
        p{
            color: $grey-color;
            margin-bottom: 0.7em;
            &:first-child{
                margin-bottom: 1.5em;
            }

            a{
            //    font-size: 0.9em;
            }
        }
    }
    .footnote{
        border-top: 1px solid $lightergrey;
        padding: 2em 0em;
        display: flex;
        justify-content: space-between;
        // font-family: fontLight;
        font-size: 0.9em;
        .x-goto{
            color: $primary;
            cursor: pointer;
        }
    }

}

// .y-enroll {
//     padding: 4em 15em;
//     background:  $very-light-primary;
//     .y-top-heading {
//       font-size: 45px;
//      color: $black-font-color;
//       text-shadow: 1px 0 #000;
//       letter-spacing: 2px;
//       font-weight: 900;
//       margin-bottom: 2rem;
//       text-align: center;
//       @include mobile(){
//         font-size: 1.5em;
//       }
//     }
//     .y-footnote {
//       font-size: 18px;
//       text-align: center;
//       margin-bottom: 2rem;
//       width: 50%;
//       margin: 0 auto;
//       letter-spacing: 1px;
//       margin-top: -0.6rem;
//       @include mobile(){
//         font-size: 1.1em;
//         width: 100%;
//       }
//     }
//     .btn-holder {
//       display: flex;
//       padding-top: 3rem;
//       button {
//         margin: 0 auto;
//       }
//     }
//   }