.x-dashboard{
  background: $very-light-primary;
  .x-main {
    // margin-top: 10em;
    padding: 10rem 8em 4em;
    @include mobile() {
      padding: 8em 1em 2em;
    }
    .y-feedback {
      margin-top: 0rem;
      margin-bottom: 2rem;
    }
    .x-top-title {
      text-shadow: 1px 0 #000;
    }
    .x-quickinfo {
      padding-top: 1em;
      .x-quick-card {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        border: 1px solid rgba($color: $light-grey,  $alpha: 0.3);
        background: $white-color;
        padding: 1rem;
        border-radius: 7px;
        @include mobile() {
          margin-top: 0em;
        }
        .info-detail {
          .top-info {
            margin-bottom: 2.5rem;
            @include mobile() {
              margin-bottom: unset;
            }
          }
          .main-imfo {
            font-weight: 900;
            text-shadow: 1px 0 #000;
          }
        }
        .icon-holder {
          // padding: 1.5rem 1rem;
          border-radius: 20px;
          height: 4rem;
          width: 4rem;
          justify-content: center;
          align-items: center;
          display: flex;
          &.purple {
            background: rgba($color: $purple,  $alpha: 0.2);
            i {
              font-size: 30px; 
              color: $purple;
             }
          }
          &.green {
            background:  rgba($color: $green,  $alpha: 0.2);
            i {
              font-size: 30px; 
              color: $green;
             }
          }
          &.yellow {
            background: $light-yellow;
            i {
              font-size: 30px; 
              color: $yellow;
             }
          }
          &.primary {
            background: $lavenderblush;
            i {
              font-size: 30px; 
              color: $primary;
             }
          }
        }
      }
    }
    .x-header-flex{
      padding: 2em 0 1em;
      display: flex;
      justify-content: space-between;
      h2{
        font-family: fontRegular;
        @include mobile(){
          font-size: 1.4em;
        }
      }
    }
    .x-card{
      background-color: $white-color;
      border: 1px solid rgba($color: $light-grey,  $alpha: 0.3);
      padding: 0 1.5em;
      border-radius: 5px;
      @include mobile(){
       margin-bottom: 1em;
      }
      .x-header-flex{
        padding: unset;
        display: flex;
        padding:  1em 0;
        justify-content: space-between;
        line-height: unset;
        align-items: center;
        h4{
         margin: 0;
         font-family: fontRegular;
        }
        i{
          color: $light-grey
        }
      }
      .x-content{
        padding: 1em 0;
        .x-not-flex{
          display: flex;
          margin-bottom: 1rem;
          align-items: center;
          &.students {
            justify-content: space-between;
          } 
          .student-info {
            display: flex;
          }
          div{
            &:nth-child(1){
              padding-right: 1em;
            }
          }
          button{
            background: rgba($color: $orange-color,  $alpha: 0.1) !important;
            color: $orange-color !important;
          }
          .message-btn {
            color: $white-color !important;
            background: $primary !important;
          }
          // .x-not-icon{
          //   background: rgba($color: $orange-color,  $alpha: 0.3);
          //   width: 50px;
          //   height: 50px;
          //   display: flex;
          //   border-radius: 100%;
          //   justify-content: center;
          //   align-items: center;
          //   i{
          //     margin: 0;
          //   }
          // }
        }
      }
      .x-more{
        padding:  0.5em 0;
        text-align: center;
        font-family: fontRegular;
        font-size: 0.9em;
        text-transform: uppercase;
        border-top:  1px solid rgba($color: $light-grey,  $alpha: 0.3);
      }
    }
  }
}