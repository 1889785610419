    .x-my-courses{
        
        .x-main{
            margin-top: 10em;

            h1{
                font-size: 3em;
            }
            .ui.pagination.menu{
                border: unset;
                box-shadow: unset;
                margin-bottom: 3em !important;
                .item:before{
                    position: unset;
                }
                a{
                    &.active{
                        // background: unset;
                        background: #f8f8f8;
                    }
                    min-width: unset;
                    padding: 1em 0.9em;
                    height: 2em;
                    outline: unset;
                    box-shadow: unset;
                    &:first-of-type, &:last-of-type{
                        background: $lightergrey;
                    }
                }
            }
        }
        .x-empty{
            padding: 10em 1em;
            min-height: 30em;
            justify-content: center;
            align-items: center;
            align-items: center;
        }
        
    }