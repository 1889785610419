.x-course{
.x-zoomsdk{
    position: relative;
    #zmmtg-root{
        position: relative;
        min-height: 400px;
        width: 100%;
        @include mobile() {
            min-height: 200px;
          }
    }
    .meeting-client, .meeting-client-inner{
        position: relative !important;
        
        .footer{
            padding: unset !important;
        }
    }
    .meeting-app, .loading-layer{
        width: unset !important;
        height: 500px !important;
    }
    .diaout-layer {
        width: 100% !important;
        height: 100%;
    }
    button{
        background: unset !important;
        color: unset !important;
    }
}
.x-main{
margin-top: 12em;
.top-container {
padding: 0 7em;
@include mobile() {
  padding: 0 0.5em;
}
}
h1{
  font-size: 3em;
  display: flex;
//   justify-content: space-between;
//   align-items: center;
  a{
      margin-left: 1em
  }
  @include mobile() {
  font-size: 1.5em
}
}
h3{
  font-size: 1.6em;
}
.editbtn {
  margin: 1rem 0;
}
.x-breadcrumb{
  padding: 1em 0;
  color: $grey-color;
  font-size: 16px;
  i{
      margin: 0 0.5em;
  }
}
.x-player {
    height: 36rem;
    @include mobile() {
        height: 24rem;
    }
}
.video-react .video-react-big-play-button{
  // display: none !important;
  background: $primary !important;
  border: unset;
}
.x-playlist{
  
  button{
      background: inherit !important
  }
}

video, .x-playlist {

  width: 100%;
  // margin: 0px 0px 30px;
  cursor: pointer;
  &:focus{
      outline: unset !important;
      border: unset !important;
}
}
.x-rightcard{
  box-shadow: $box-shadow;
  // width: 95%;
  // margin-left: 2rem;
  @include mobile() {
      margin-bottom: 2rem;
  }
  .x-avatar{
      div {
          line-height: 16px;
      }
          img{
              width: 60px;
              height: 60px;
              object-fit: cover

          }
          h4{
              font-size: 1.2em;
              margin-bottom: 0.5em;
              text-transform: capitalize;
          }
          span{
              color: $grey-color;
              font-size: 13px;
          }
  }
  &.package{
      .x-package {
          p {
              margin: 1rem 0;
              font-size: 13px;
          }
      }
      h3{
          font-size: 1.5em;
          font-weight: 500;
          text-shadow: 1px 0 #000;
      }
      .x-avatar{
      div {
          line-height: 16px;
      }
          img{
              width: 60px;
              height: 60px;
              object-fit: cover

          }
          h4{
              font-size: 1.2em;
              margin-bottom: 0.5em;
              text-transform: capitalize;
          }
          span{
              color: $grey-color;
              font-size: 13px;
          }
      }
  }
  .x-header{
      padding: 2em 2em 1em;
      border-bottom: 1px solid $lightergrey;
      .x-avatar{
          display: flex;
          padding: 1em 0em;
          font-size: 0.8em;
          div {
          line-height: 16px;
          font-size: 15px;
      }
          img{
              margin-right: 1em;
              object-fit: cover
          }
          i{
              font-size: 0.6em;
          }
      }
  }
  .x-segment{
      border-bottom: 1px solid $lightergrey;
      padding: 1em 2em ;

      h4{
          font-size: 0.9em;
          font-family: fontRegular;
          font-size: 15px;
      }
  }
  .x-details{
      padding: 2em ;
      i{
          color: $orange-color;
      }
  }
  .x-package{
      padding: 2em;
      p{
          font-size: 0.9em;
          margin: 1em 0 2.5em;
      }
      .hidden.content {
      margin-top: -1em
      }
      .x-card-pricing{
          display: flex;
          align-items: center;
          margin: 0 0 2em;
          h4{
              display: inline;
              font-size: 2.2em;
              font-family: fontRegular;
              margin: 0 ;
          }
          span{
              margin-left: 0.5em;
              color: $grey-color;
              font-size: 1.2em;
          }
          
      }
  }
}

}

}