.y-terms {
  margin-top: 10em;
  padding: 3em 8em 4em;
  @include mobile() {
    padding: 3em 2em;
  }
  .y-main {
    margin-bottom: 4rem;
    .top-heading {
      font-size: 3em;
      text-align: center;
      text-shadow: 1px 0 #000;
      margin-bottom: 2rem;
      @include mobile() {
        font-size: 1.5em;
      }
    }
    h2 {
      text-shadow: 1px 0 #000;
    }
    p, li {
      font-size: 15px;
    }
  }
}