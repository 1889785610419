.x-settings{
    .x-main {
        margin-top: 8em;
        padding: 3em 8em 4em;
        @include mobile() {
          padding: 3em 1em;
        }
        h1{
          font-size: 3em;
          margin-bottom: 1em;
          @include mobile() {
          font-size: 1.5em
        }
    }
        .x-card{
            border: 1px solid #eee;
            box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.09);

            .img-holder {
                background-position: center;
                background-size: cover;
                width: 100%;
                height: 20rem;
                // width: 15rem;
                // border-radius: 50%;
                background-repeat: no-repeat;
                margin: 0 auto;
                // margin-bottom: 0.4rem;
                // img {
                //   height: 5rem;
                //   border-radius: 50%;
                //   width: 5rem;
                // }
                &.showononlylarge {
                  @include mobile() {
                    display: none;
                  }
                }
                &.showononlysmall {
                  display: none;
                  @include mobile() {
                    display: block;
                    margin-bottom: 2rem;
                  }
                }
              }
            .x-content{
                padding: 1.5em;
                p{
                    color: $grey-color;
                }
                h3{
                    text-transform: capitalize;
                }
                .x-details{
                    border-top: 1px solid #eee;
                    margin: 1.5em -0.5em 0;
                    p{
                        margin: 0.5em;

                    }
                    a{
                        display: block;
                        padding: 0.3em 1em;
                        border-radius: 5px;
                        color: $grey-color;
                        &.active{
                            background: $primary;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .x-right-card{
            border: 1px solid #eee;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);
            padding: 2em;
            h3{
                // font-family: fontMedium;
            }
            p{
                    font-size: 0.8em;
                    color: $grey-color;
                    text-transform: uppercase;
                }
            .btn-holder{
                padding: 2em 1em 0;
                margin-top: 2em;
                border-top: 1px solid #eee;
            }
            .x-input-holder{
              margin-bottom: 1em;
              p{
                // margin: 0;
              }
            }
        }
    }
}