.y-video-modal {
  input {
    display: none;
  }
  .vidoe-item {
    border-radius: 5px;
    border: 2px solid $Light-border;
    padding: 0.5rem 1rem;
    display: flex;
    margin-bottom: 1rem;
    cursor: pointer;
    .video-details {
      p {
        margin-bottom: 0.5rem;
        line-height: 20px;
      }
    }
  }
}