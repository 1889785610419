.x-paid-accordion{
    .x-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 2em !important;
        font-size: 0.8em !important;
        text-transform: capitalize !important;
        i{
            color: $primary !important;
        }
        &.active{
        background: $primary;
        color: $white-color !important;
        i{
            color: $white-color !important;
        }
        }
    }
    .x-content{
        padding: 0 2em 2em !important;
        .x-lecs{
            display: flex !important;
            padding-top: 2em !important;

            .ui.checkbox .box:before, .ui.checkbox label:before{
                border-color: $primary;

            }
            .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after{
                background: $primary;
                color: $white-color;
            }
            .x-desc{
                margin-left: 1em;
                i{
                    color: $primary ;
                    
                }
                div{
                    font-size: 0.8em;
                
                }
            }
        }
    }
    .x-load-more{
        padding: 2em;
        font-size: 0.8em;
        text-align: center;
    }
}