.y-createcourse {
  background: $very-light-primary;
  .y-course-success {
    padding: 20rem 0 10rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    @include mobile() {
      width: 100%;
      padding: 20rem 2em 10rem;
    }
    h1 {
      font-size: 35px;
      color: $black-font-color;
      text-shadow: 1px 0 #000;
      letter-spacing: 2px;
      font-family: fontBold;
      margin-bottom: -1rem
    }
    span {
      margin: 2rem 0 3rem;
      font-size: 18px;
    }
    i.check.circle.outline.icon {
      font-size: 100px;
      text-align: center;
      width: 100%;
      color: $primary;
    }
    button {
      height: 5rem;
      width: 40%;
      margin: 0 auto;
      font-size: 20px;
      @include mobile() {
        width: 80%;
      }
    }
  }
  .y-main {
    width: 35%;
    padding: 15rem 0 5rem;
    // margin: 15rem auto 5rem;
    margin: 0 auto;
    @include mobile() {
      width: 92%;
    }
    .top-title {
      text-align: center;
    }
    .progressbar {
      display: flex;
      position: relative;
      counter-reset: step;
      list-style: none;
      padding: 4rem 0 1rem;
      li:first-child:after {
        content: none;
      }
      li {
        float: left;
        width: 33.33%;
        position: relative;
        text-align: center;
        // pointer-events: none;
        cursor: pointer;
        &.active, &.completed {
          color: $primary;
          &+li:after {
            background: $primary;
          }
          &:before {
            background: $primary;
            color: $white-color;
          }
        }
        &:before {
          content: counter(step);
          counter-increment: step;
          width: 30px;
          height: 30px;
          border: 1px solid #ddd;
          display: block;
          line-height: 30px;
          text-align: center;
          margin: 0px auto 10px auto;
          border-radius: 50%;
          background: $white-color;
          z-index: 100;
          position: relative;
        }
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          background: #ddd;
          top: 15px;
          left: -50%;
          display: block;
          z-index: 1;
        }
      }
    }
    .y-slick-holder {
      .y-slide {
      background: $white-color;
      border-radius: 5px;
      margin-top: 3rem;
      padding: 2em 2em 2em;
      }
    }
    .y-form {
      // height: 30rem;
      // overflow-y: scroll;
      .y-slider-head {
        &.withbtn {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          button {
            margin-bottom: 1rem;
          }
        }
      }
      .y-section-holder {
        background: $very-light-primary;
        padding: 1rem;
        margin-bottom: 1rem;
        .top {
          display: flex;
          justify-content: space-between;
          .likebtn {
            cursor: pointer;
          }
        }
        .y-lecture-holder {
          padding: 1rem;
          background: $white-color;
          margin-bottom: 1rem;
          .top {
            display: flex;
            justify-content: space-between;
            .likebtn {
              cursor: pointer;
            }
          }
          .lecture-vid {
            display: flex;
            justify-content: space-between;
            video {
              height: 13rem;
              width: 98%;
              border-radius: 5px;
              margin-bottom: 1rem;
              outline: none;
            }
            i.times.icon {
              cursor: pointer;
            }
          }
        }
      }
      .y-files-holder {
        background: $very-light-primary;
        padding: 1rem;
        margin-bottom: 1rem;
        i.times.icon {
          cursor: pointer;
        }
        .fileslist-holder {
          .item {
            &:before {
              top: 0.5rem
            }
          }
          .list-content {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            @include mobile() {
              flex-direction: column;
              p {
                width: 50%;
              }
          }
          }
        }
      }
      .y-input-holder {
        padding: 0.5rem 0;
        position: relative;
        @include mobile() {
          #price {
            margin-top: 1rem;
          }
        }
        .custom-label {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 1rem;
          p {
            margin-bottom: 0;
          }
        }
        .courseimage-uploadfile {
          display: none;
        }
        .inputwithx {
          display: flex;
          justify-content: space-between;
          div:first-child {
            width: 98%;
          }
          i.times.icon {
            cursor: pointer;
          }
        }
        .addanswer {
          cursor: pointer;
        }
        .courseimage-holder {
          border: 1px solid $lightergrey;
          margin-bottom: 1rem;
          &.withimg {
            background: url('https://res.cloudinary.com/dzvhmiutm/image/upload/v1589117091/xx0qnrr66ocwl94d6676.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 20rem;
          }
          .click {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 20rem;
            padding: 3rem 0;
            border-radius: 5px;
            // cursor: pointer;
            i.image.icon {
              font-size: 5rem;
              margin-top: 3rem;
            }
            p {
              margin-bottom: 0em;
            }
            button {
              width: 30%;
              margin-top: 0.2rem;
              @include  mobile() {
                width: 50%;
              }
            }
          }
        }
      }
      .ui.selection.dropdown {
        width: 100%;
        padding-top: 0.5rem;
      }
      .slide-nav,.publishorsave {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        align-items: center;
        @include mobile() {
          flex-direction: column-reverse;
          .y-navbtn:first-child {
            margin-top: 1rem;
          }
        }
      }
      .publishorsave {
        button {
          width: 100%;
        }
      }
      .y-savebtn {
        @include mobile() {
          margin-top: 1rem;
        }
      }
      // .y-nextbtn {
      //   margin-top: 1rem;
      //   height: 3.5rem;
      // }
      .ui.selection.active.dropdown .menu {
        border-color: transparent;
      }
      input, .ui.selection, textarea{
        // background: $input-grey-color;
        box-shadow: unset;
        outline: unset;
        border: 1px solid $lightergrey;
        color: $black-font-color;
        // border: 1px solid $input-grey-border-color;
        margin-bottom: 0em;
        font-size: 0.9em;
        height: 3rem;
        &:focus{
        // background: $input-grey-color;
        }
      }
    }
  }
}