.y-checkout{
  .y-main {
    margin-top: 10em;
    padding: 0rem 8em 4em;
    @include mobile(){
      padding: 3rem 1rem
    }
    .y-top-title {
      text-shadow: 1px 0 #000;
    }
    .y-input-holder  {
      &.name-on-card {
        @include mobile() {
          margin-bottom: 2rem;
        }
      }
    }
    .y-item-holder {
      border-radius: 5px;
      border: 1px solid $Light-border;

      .y-top-heading {
        // text-shadow: 1px 0 #000;
        padding: 2rem 2.5rem 0px;
        text-shadow: 0.5px 0 #000;
      }
      .y-item {
        padding: 1rem 0rem 1rem 2.5rem;
        border-bottom: 1px solid $Light-border;
        // margin-bottom: 1rem;
        @include mobile() {
          padding: 1rem 0rem 1rem 2rem;
        }
        .img-holder {
          img {
            height: 3.8rem;
            border-radius: 5px;
            width: 3.8rem;
          }
        }
        .course-det {
          padding-left: 2rem;
          h4 {
            margin-bottom: 0.5rem;
            text-shadow: 0.5px 0 #000;
          }
        }
        .y-pricing-holder {
          display: flex;
          .discounting {
            margin-right: 1rem;
          }
        }
        .y-pricing {
          font-size: 25px;
          text-shadow: 1px 0 #000;
          letter-spacing: 1px;
          // padding: 0 1em;
        }
        .y-remove {
          font-size: 20px;
          color: $Light-border;
          cursor: pointer;
        }
      }
      .y-discount {
        padding: 1.5rem 0px;
        text-align: center;
        cursor: pointer;
      }
    }
    .y-payment {
      border-radius: 5px;
      border: 1px solid $Light-border;
      margin-top: 2rem;
      .y-top-heading {
        // text-shadow: 1px 0 #000;
        padding: 2rem 2.5rem 0px;
        text-shadow: 0.5px 0 #000;
      }
      .y-method {
        display: flex;
        justify-content: space-between;
        padding: 2rem 2rem;
        margin: 0 2.5rem;
        background: $very-light-primary;
        border-radius: 5px;
        .method {
          text-shadow: 0.5px 0 #000;
        }
        .imgs-holder {
          display: flex;
          img {
            height: 1rem;
            &:first-child {
              margin-right: 1rem;
            }
          }
        }
      }
      .y-form-main {
        padding: 2rem 2.5rem 3rem; 
      }
    }
    .y-summary {
      box-shadow: $box-shadow;
      // @include mobile() {
      //   margin-top: 2rem;
      // }
      .y-top-heading {
        // text-shadow: 1px 0 #000;
        padding: 2rem 2.5rem 0px;
        text-shadow: 0.5px 0 #000;
      }
      .y-breakdowns {
        padding: 2rem 2.5rem;
        border-bottom: 1px solid $Light-border;
        .y-breakdown {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
        }
      }
      .y-total {
        padding: 2rem 2.5rem;
        .total-info {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          color: $primary;
        }
        button {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }

    .StripeElement {
      font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
      margin: 0;
      outline: 0;
      -webkit-appearance: none;
      tap-highlight-color: rgba(255,255,255,0);
      line-height: 1.21428571em;
      padding: .67857143em 1em;
      font-size: 1em;
      background: #fff;
      border: 1px solid rgba(34,36,38,.15);
      border-radius: .28571429rem;
      -webkit-box-shadow: 0 0 0 0 transparent inset;
      box-shadow: 0 0 0 0 transparent inset;
      -webkit-transition: color .1s ease,border-color .1s ease;
      transition: color .1s ease,border-color .1s ease;

    }
    .StripeElement--focus {
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }
  }
}
