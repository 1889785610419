.x-signin,.x-signup{
    // width: 100%;
    padding-top: 10em;
    // position: absolute;
    // min-height: 100%;
    // width: 100%;
    color: $black-font-color;
    background: $light-white;
    .ui.grid{
        margin:0;
    }
    .x-socialFlex{
        display: flex;
        justify-content: space-between;
        @include mobile(){
            display: block;
        }
    }
    .passport.facebook{
        background: #3b5998  !important
    }
    .passport.google{
        background:#fff  !important;
        color: #000 !important;
        border: 1px solid rgba($color: $light-grey, $alpha: 0.5);
    }
    .x-main{
        width: 35%;
        margin: 5vh auto 4em;
        @include mobile(){
            width:95%;
            margin: 0 auto ;
        }
        @include tablet(){
            width:70%;
            margin: 5vh auto ;
        }

    .progressbar {
      display: flex;
      position: relative;
      counter-reset: step;
      list-style: none;
      padding: 4rem 0 1rem;
      li:first-child:after {
        content: none;
      }
      li {
        float: left;
        width: 33.33%;
        position: relative;
        text-align: center;
        // pointer-events: none;
        cursor: pointer;
        &.active, &.completed {
          color: $primary;
          &+li:after {
            background: $primary;
          }
          &:before {
            background: $primary;
            color: $white-color;
          }
        }
        &:before {
          content: counter(step);
          counter-increment: step;
          width: 30px;
          height: 30px;
          border: 1px solid #ddd;
          display: block;
          line-height: 30px;
          text-align: center;
          margin: 0px auto 10px auto;
          border-radius: 50%;
          background: $white-color;
          z-index: 100;
          position: relative;
        }
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 3px;
          background: #ddd;
          top: 15px;
          left: -50%;
          display: block;
          z-index: 1;
        }
      }
    }
        .x-body{
            width:100%;
            .x-heading{
                text-align: center;
                padding: 0 0 1em;
                h2{
                    margin: 0 0 0.5em;
                    font-size: 1.5em;
                    font-family: fontRegular
                }
            }
        .x-card{
            padding: 3em 2.5em 2em;
            background: $white-color;
            margin: 2vh 0em ;
            @include mobile() {
                margin: 2vh 0em 3rem;
            }
            .x-info{
                margin-bottom: 1em;
                margin-top: -0.5em;
            }
          
            .x-tos{
                font-size: 0.9em;
                text-align: center;
                color: $grey-color;
            }
            .x-type{
                display: flex;
                .ui.radio.checkbox input:checked~.box:before, .ui.radio.checkbox input:checked~label:before{
                    border-color: $primary;

                }
                .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after{
                    background: $primary;
                }
                .field:first-child{
                    margin-right: 2em;
                }

            }
            .xlabel{
                display: flex;
                font-size: 0.9em;

                // p{
                //     margin: 1em 0 1em 0;
                // }
                p:first-child{
                    margin: 1em auto 1em 0;
                }
                a{
                    // color: $black-font-color
                }
            }
            label{
                font-size: 0.9em !important
            }
            input{
                // background: $input-grey-color;
                box-shadow: unset;
                outline: unset;
                border: 1px solid $lightergrey;
                color: $black-font-color;
                // border: 1px solid $input-grey-border-color;
                margin-bottom: 0em;
                font-size: 0.9em;
               
                &:focus{
                // background: $input-grey-color;
                }
                
            }
            .password{
                // input::placeholder{
                //     font-size: 1.5em;
                //     letter-spacing: 0.2em;
                // }
            }
            .x-forgot{
                display: flex;
                justify-content: space-between;
                font-size: 0.9em;
                a{
                    text-decoration: underline;
                }
                .ui.checkbox{
                    margin-bottom: 2em;
                }
            }
            button{
                background: $primary;
                // width: 80%;
                margin: 2em auto 1em ;
                font-family: fontLight;
                &:focus,&:hover{
                    // background: $blue-hover-color
                }
            }
        }

        }
        }

        .x-bottom{
            text-align: center;
            padding: 1em 0;
            font-size: 0.9em;
            a{
                color:$primary;
                font-weight: bolder;
                font-family: fontRegular
            }
        }

    &.x-getstarted{
        box-shadow: $box-shadow;
        .x-card{
            padding: unset !important;
            background: unset !important;
            margin: unset !important;
        }
        .x-header-flex{
            display: flex;
            justify-content: space-between;
            align-items: center;
            button, h3{
                margin: 0 !important
            }
        }
        .x-slide{
            padding: 3em 2.5em 2em;
            background: #fff;
            margin: 2vh 0em;
        }
        .progressbar{
            padding: 1rem 0 1rem
        }
    }
}