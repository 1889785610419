.y-teachers {
  margin-top: 8em;
  
  .y-main {
    padding: 3em 8em 4em;
    @include mobile() {
      padding: 3em 2em;
    }
    .x-flexhead{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mobile(){
        display: block;
      }
      .inputWrapper{
        box-shadow: $box-shadow;
     
        @include mobile(){
          margin-bottom: 1em;
          width: 100%;
        }
    }
    input {
        border: 0;
        border-radius: unset;
        &::placeholder{
            color:  $grey-color !important
        }
    }
    }
    .top-heading {
      font-size: 3em;
        @include mobile() {
        font-size: 1.5em
      }
    }
    .y-searchbox {
      margin: 2rem 0;
    }
    .teacher-holder {
     
      .ui.pagination.menu{
        border: unset;
        box-shadow: unset;
        margin-bottom: 3em !important;
        .item:before{
            position: unset;
        }
        a{
            &.active{
                // background: unset;
                background: #f8f8f8;
              }
            min-width: unset;
            padding: 1em 0.9em;
            height: 2em;
            outline: unset;
            box-shadow: unset;
            &:first-of-type, &:last-of-type{
                background: $lightergrey;
            }
        }
    }
    }
  }
}
.y-instr-card {
  // width: 95% !important;
  margin-bottom: 4rem;
  cursor: pointer;
  &:hover {
    .y-name {
      &:after {
        opacity: 1;
      }
    }
  }
  .img-holder {
    margin-bottom: 2rem;
    img {
      border-radius: 7px;
      height: 23rem;
      width: 100%;
      object-fit: cover;
    }
  }
  .y-name {
    font-size: 16px;
   color: $black-font-color;
   font-family: fontRegular;
    margin-bottom: 1rem;
    text-transform: capitalize;
            position: relative;
            &:after {
              -webkit-transition: opacity .5s ease-in-out;
              -moz-transition: opacity .5s ease-in-out;
              -o-transition: opacity .5s ease-in-out;
              transition: opacity .5s ease-in-out;
              display: block;
              content: "";
              width: 50%;
              height: 0.3rem;
              background: $primary;
              position: absolute;
              bottom: 0;
              left: 0;
              top: 1.7rem;
              opacity: 0;
            }
  }
  .y-rate {
    font-size: 16px;
    color: $orange-color;
    font-weight: 900;
    margin-bottom: 1rem;
  }
  .y-desc {
    font-size: 14px;
    margin-top: -0.6rem;
  }
}